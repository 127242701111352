import { ReactNode } from 'react';

import cn from 'classnames';

interface Props {
  index?: number;
  children: ReactNode;
}

const SectionTitle = ({ index, children }: Props) => {
  return (
    <div
      className={cn(
        'cursor-pointer p-3 text-center uppercase tracking-[0.2em] text-white',
        {
          'bg-af-blue-dark': index % 2 === 0,
          'bg-af-gray-extradark': index % 2 !== 0,
        },
      )}
    >
      {children}
    </div>
  );
};

export default SectionTitle;
